.skills__list {
    max-width: 450px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .skills__list-item {
    margin: 0.5em;
  }

  .btn {
    display: block;
    padding: 0.8em 1.4em;
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: lowercase;
    transition: transform 0.2s ease-in-out;
  }
  
  .btn--outline {
    color: var(--clr-primary);
    border: 2px solid var(--clr-primary);
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .btn--outline:focus,
  .btn--outline:hover {
    color: var(--clr-bg);
  }
  
  .btn--outline:before {
    content: "";
    position: absolute;
    background-color: var(--clr-primary);
    right: 100%;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: -1;
    transition: right 0.2s ease-in-out;
  }
  
  .btn--outline:hover::before,
  .btn--outline:focus::before {
    right: 0;
  }
  
  .btn--plain {
    text-transform: initial;
    background-color: #162e47;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    border: 0;
  }
  
  .btn--plain:hover {
    transform: translateY(-4px);
  }
  
  .btn--icon {
    padding: 0;
    font-size: 1.2rem;
  }
  
  .btn--icon:hover,
  .btn--icon:focus {
    color: var(--clr-primary);
  }
  
  .btn--icon:active {
    transform: translateY(-5px);
  }

  .project {
    padding: 2em;
    margin: 0 auto;
    text-align: center;
    transition: transform 0.2s linear;
  }
  
  .project:hover {
    transform: translateY(-7px);
  }